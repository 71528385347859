export const Table_28 = [
  {
    Code: "01",
    Text: "Frequentou formação profissional no ano de referência",
  },
  {
    Code: "02",
    Text: "Recebeu compensação monetária ou crédito de horas em substituição da frequência de formação profissional a que tinha direito (artº 132 e artº 134, Lei 7/2009 de 12 de fevereiro)",
  },
  {
    Code: "03",
    Text: `Em substituição da frequência a formação profissional usou as horas respetivas para frequência a aulas ou prestação de provas de avaliação, ao abrigo do regime de trabalhador – 
      estudante, bem como no âmbito de processo de reconhecimento, validação e certificação de competências (nº 4, artº 131, Lei 7/2009 de 12 de fevereiro)`,
  },
  {
    Code: "08",
    Text: "Outra situação (não frequência de formação profissional, nem nenhuma das situações descritas nos Codes anteriores)",
  },
];

export function GetTypeTextById(id) {
  for (let index = 0; index < Table_28.length; index++) {
    if (Table_28[index].TypeId === id) {
      return Table_28[index].Text;
    }
  }
  return "";
}
