export const Table_30 = [
  {
    Code: "010",
    Text: "Programas de base",
    Hint: "Programas gerais / Programas de base",
  },
  {
    Code: "080",
    Text: "Alfabetização",
    Hint: "Programas gerais / Alfabetização",
  },
  {
    Code: "090",
    Text: "Desenvolvimento pessoal",
    Hint: "Programas gerais / Desenvolvimento pessoal",
  },
  {
    Code: "142",
    Text: "Ciências da educação",
    Hint: "Educação / Formação de professores/ formadores e ciências da educação",
  },
  {
    Code: "143",
    Text: "Formação de professores da educação pré-escolar",
    Hint: "Educação / Formação de professores/ formadores e ciências da educação",
  },
  {
    Code: "144",
    Text: "Formação de professores do ensino básico",
    Hint: "Educação / Formação de professores/ formadores e ciências da educação",
  },
  {
    Code: "145",
    Text: "Formação de professores de áreas disciplinares específicas",
    Hint: "Educação / Formação de professores/ formadores e ciências da educação",
  },
  {
    Code: "146",
    Text: "Formação de professores e formadores de áreas tecnológicas",
    Hint: "Educação / Formação de professores/ formadores e ciências da educação",
  },
  {
    Code: "149",
    Text: "Formação de professores/ formadores e ciências da educação - programas não classificados noutra área de formação.",
    Hint: "Educação / Formação de professores/ formadores e ciências da educação",
  },
  {
    Code: "211",
    Text: "Belas Artes",
    Hint: "Artes e humanidades / Artes",
  },
  {
    Code: "212",
    Text: "Artes do Espectáculo",
    Hint: "Artes e humanidades / Artes",
  },
  {
    Code: "213",
    Text: "Audiovisuais e produção dos media",
    Hint: "Artes e humanidades / Artes",
  },
  {
    Code: "214",
    Text: "Design",
    Hint: "Artes e humanidades / Artes",
  },
  {
    Code: "215",
    Text: "Artesanato",
    Hint: "Artes e humanidades / Artes",
  },
  {
    Code: "219",
    Text: "Artes - programas não classificados noutra área de formação",
    Hint: "Artes e humanidades / Artes",
  },
  {
    Code: "221",
    Text: "Religião e teologia",
    Hint: "Artes e humanidades / Humanidades",
  },
  {
    Code: "222",
    Text: "Línguas e literaturas estrangeiras",
    Hint: "Artes e humanidades / Humanidades",
  },
  {
    Code: "223",
    Text: "Língua e literatura materna",
    Hint: "Artes e humanidades / Humanidades",
  },
  {
    Code: "225",
    Text: "História e arqueologia",
    Hint: "Artes e humanidades / Humanidades",
  },
  {
    Code: "226",
    Text: "Filosofia e ética",
    Hint: "Artes e humanidades / Humanidades",
  },
  {
    Code: "229",
    Text: "Humanidades - programas não classificados noutra área de formação",
    Hint: "Artes e humanidades / Humanidades",
  },
  {
    Code: "311",
    Text: "Psicologia",
    Hint: "Ciências sociais, comércio e direito / Ciências sociais e do comportamento",
  },
  {
    Code: "312",
    Text: "Sociologia e outros estudos",
    Hint: "Ciências sociais, comércio e direito / Ciências sociais e do comportamento",
  },
  {
    Code: "313",
    Text: "Ciência política e cidadania",
    Hint: "Ciências sociais, comércio e direito / Ciências sociais e do comportamento",
  },
  {
    Code: "314",
    Text: "Economia",
    Hint: "Ciências sociais, comércio e direito / Ciências sociais e do comportamento",
  },
  {
    Code: "319",
    Text: "Ciências sociais e do comportamento - programas não classificados noutra área de formação",
    Hint: "Ciências sociais, comércio e direito / Ciências sociais e do comportamento",
  },
  {
    Code: "321",
    Text: "Jornalismo e reportagem",
    Hint: "Ciências sociais, comércio e direito /",
  },
  {
    Code: "322",
    Text: "Biblioteconomia, arquivo e documentação (BAD)",
    Hint: "Ciências sociais, comércio e direito /",
  },
  {
    Code: "329",
    Text: "Informação e jornalismo - Programas não classificados noutra área de formação",
    Hint: "Ciências sociais, comércio e direito /",
  },
  {
    Code: "341",
    Text: "Comércio",
    Hint: "Ciências sociais, comércio e direito / Ciências empresariais",
  },
  {
    Code: "342",
    Text: "Marketing e publicidade",
    Hint: "Ciências sociais, comércio e direito / Ciências empresariais",
  },
  {
    Code: "343",
    Text: "Finanças, banca e seguros",
    Hint: "Ciências sociais, comércio e direito / Ciências empresariais",
  },
  {
    Code: "344",
    Text: "Contabilidade e fiscalidade",
    Hint: "Ciências sociais, comércio e direito / Ciências empresariais",
  },
  {
    Code: "345",
    Text: "Gestão e administração",
    Hint: "Ciências sociais, comércio e direito / Ciências empresariais",
  },
  {
    Code: "346",
    Text: "Secretariado e trabalho administrativo",
    Hint: "Ciências sociais, comércio e direito / Ciências empresariais",
  },
  {
    Code: "347",
    Text: "Enquadramento na organização/ empresa",
    Hint: "Ciências sociais, comércio e direito / Ciências empresariais",
  },
  {
    Code: "349",
    Text: "Ciências empresariais - programas não classificados noutra área de formação.",
    Hint: "Ciências sociais, comércio e direito / Ciências empresariais",
  },
  {
    Code: "380",
    Text: "Direito",
    Hint: "Ciências sociais, comércio e direito / Direito",
  },
  {
    Code: "421",
    Text: "Biologia e bioquímica",
    Hint: "Ciências, matemática e informática / Ciências da vida",
  },
  {
    Code: "422",
    Text: "Ciências do ambiente",
    Hint: "Ciências, matemática e informática / Ciências da vida",
  },
  {
    Code: "429",
    Text: "Ciências da vida - programas não classificados noutra área de formação",
    Hint: "Ciências, matemática e informática / Ciências da vida",
  },
  {
    Code: "441",
    Text: "Física",
    Hint: "Ciências, matemática e informática / Ciências físicas",
  },
  {
    Code: "442",
    Text: "Química",
    Hint: "Ciências, matemática e informática / Ciências físicas",
  },
  {
    Code: "443",
    Text: "Ciências da terra",
    Hint: "Ciências, matemática e informática / Ciências físicas",
  },
  {
    Code: "449",
    Text: "Ciências físicas - programas não classificados noutra área de formação",
    Hint: "Ciências, matemática e informática / Ciências físicas",
  },
  {
    Code: "461",
    Text: "Matemática",
    Hint: "Ciências, matemática e informática / Matemática e estatística",
  },
  {
    Code: "462",
    Text: "Estatística",
    Hint: "Ciências, matemática e informática / Matemática e estatística",
  },
  {
    Code: "469",
    Text: "Matemática e estatística - programas não classificados noutra área de formação",
    Hint: "Ciências, matemática e informática / Matemática e estatística",
  },
  {
    Code: "481",
    Text: "Ciências informáticas",
    Hint: "Ciências, matemática e informática / Informática",
  },
  {
    Code: "482",
    Text: "Informática na óptica do utilizador",
    Hint: "Ciências, matemática e informática / Informática",
  },
  {
    Code: "489",
    Text: "Informática - programas não classificados noutra área de formação",
    Hint: "Ciências, matemática e informática / Informática",
  },
  {
    Code: "521",
    Text: "Metalurgia e metalomecânica",
    Hint: "Engenharia, indústrias transformadoras e construção / Engenharia e técnicas afins",
  },
  {
    Code: "522",
    Text: "Electricidade e energia",
    Hint: "Engenharia, indústrias transformadoras e construção / Engenharia e técnicas afins",
  },
  {
    Code: "523",
    Text: "Electrónica e automação",
    Hint: "Engenharia, indústrias transformadoras e construção / Engenharia e técnicas afins",
  },
  {
    Code: "524",
    Text: "Tecnologia dos processos químicos",
    Hint: "Engenharia, indústrias transformadoras e construção / Engenharia e técnicas afins",
  },
  {
    Code: "525",
    Text: "Construção e reparação de veículos a motor",
    Hint: "Engenharia, indústrias transformadoras e construção / Engenharia e técnicas afins",
  },
  {
    Code: "529",
    Text: "Engenharia e técnicas afins - programas não classificados noutra área de formação",
    Hint: "Engenharia, indústrias transformadoras e construção / Engenharia e técnicas afins",
  },
  {
    Code: "541",
    Text: "Indústrias alimentares",
    Hint: "Engenharia, indústrias transformadoras e construção / Indústrias transformadoras",
  },
  {
    Code: "542",
    Text: "Indústrias do têxtil, vestuário, calçado e couro",
    Hint: "Engenharia, indústrias transformadoras e construção / Indústrias transformadoras",
  },
  {
    Code: "543",
    Text: "Materiais (indústrias da madeira, cortiça, papel, plástico, vidro e outros)",
    Hint: "Engenharia, indústrias transformadoras e construção / Indústrias transformadoras",
  },
  {
    Code: "544",
    Text: "Indústrias extractivas",
    Hint: "Engenharia, indústrias transformadoras e construção / Indústrias transformadoras",
  },
  {
    Code: "549",
    Text: "Indústrias transformadoras - programas não classificados noutra área de formação",
    Hint: "Engenharia, indústrias transformadoras e construção / Indústrias transformadoras",
  },
  {
    Code: "581",
    Text: "Arquitectura e urbanismo",
    Hint: "Engenharia, indústrias transformadoras e construção / Arquitectura e construção",
  },
  {
    Code: "582",
    Text: "Construção civil e engenharia civil",
    Hint: "Engenharia, indústrias transformadoras e construção / Arquitectura e construção",
  },
  {
    Code: "589",
    Text: "Arquitectura e construção - programas não classificados noutra área de formação",
    Hint: "Engenharia, indústrias transformadoras e construção / Arquitectura e construção",
  },
  {
    Code: "621",
    Text: "Produção agrícola e animal",
    Hint: "Agricultura / Agricultura, silvicultura e pescas",
  },
  {
    Code: "622",
    Text: "Floricultura e jardinagem",
    Hint: "Agricultura / Agricultura, silvicultura e pescas",
  },
  {
    Code: "623",
    Text: "Silvicultura e caça",
    Hint: "Agricultura / Agricultura, silvicultura e pescas",
  },
  {
    Code: "624",
    Text: "Pescas",
    Hint: "Agricultura / Agricultura, silvicultura e pescas",
  },
  {
    Code: "629",
    Text: "Agricultura, silvicultura e pescas - programas não classificados noutra área de formação",
    Hint: "Agricultura / Agricultura, silvicultura e pescas",
  },
  {
    Code: "640",
    Text: "Ciências veterinárias",
    Hint: "Agricultura / Ciências veterinárias",
  },
  {
    Code: "721",
    Text: "Medicina",
    Hint: "Saúde e protecção social /",
  },
  {
    Code: "723",
    Text: "Enfermagem",
    Hint: "Saúde e protecção social /",
  },
  {
    Code: "724",
    Text: "Ciências dentárias",
    Hint: "Saúde e protecção social /",
  },
  {
    Code: "725",
    Text: "Tecnologias de diagnóstico e terapêutica",
    Hint: "Saúde e protecção social /",
  },
  {
    Code: "726",
    Text: "Terapia e reabilitação",
    Hint: "Saúde e protecção social /",
  },
  {
    Code: "727",
    Text: "Farmácia",
    Hint: "Saúde e protecção social /",
  },
  {
    Code: "729",
    Text: "Saúde - programas não classificados noutra área de formação",
    Hint: "Saúde e protecção social /",
  },
  {
    Code: "761",
    Text: "Serviços de apoio a crianças e jovens",
    Hint: "Saúde e protecção social /",
  },
  {
    Code: "762",
    Text: "Trabalho social e orientação",
    Hint: "Saúde e protecção social /",
  },
  {
    Code: "769",
    Text: "Serviços sociais - programas não classificados noutra área de formação",
    Hint: "Saúde e protecção social /",
  },
  {
    Code: "811",
    Text: "Hotelaria e restauração",
    Hint: "Serviços / Serviços pessoais",
  },
  {
    Code: "812",
    Text: "Turismo e lazer",
    Hint: "Serviços / Serviços pessoais",
  },
  {
    Code: "813",
    Text: "Desporto",
    Hint: "Serviços / Serviços pessoais",
  },
  {
    Code: "814",
    Text: "Serviços domésticos",
    Hint: "Serviços / Serviços pessoais",
  },
  {
    Code: "815",
    Text: "Cuidados de beleza",
    Hint: "Serviços / Serviços pessoais",
  },
  {
    Code: "819",
    Text: "Serviços pessoais - programas não classificados noutra área de formação",
    Hint: "Serviços / Serviços pessoais",
  },
  {
    Code: "840",
    Text: "Serviços de transporte",
    Hint: "Serviços / Serviços de transporte",
  },
  {
    Code: "851",
    Text: "Tecnologia de protecção do ambiente",
    Hint: "Serviços / Protecção do ambiente",
  },
  {
    Code: "852",
    Text: "Ambientes naturais e vida selvagem",
    Hint: "Serviços / Protecção do ambiente",
  },
  {
    Code: "853",
    Text: "Serviços de saúde pública",
    Hint: "Serviços / Protecção do ambiente",
  },
  {
    Code: "859",
    Text: "Protecção do ambiente - programas não classificados noutra área de formação",
    Hint: "Serviços / Protecção do ambiente",
  },
  {
    Code: "861",
    Text: "Protecção de pessoas e bens",
    Hint: "Serviços / Serviços de segurança",
  },
  {
    Code: "862",
    Text: "Segurança e higiene no trabalho",
    Hint: "Serviços / Serviços de segurança",
  },
  {
    Code: "863",
    Text: "Segurança militar",
    Hint: "Serviços / Serviços de segurança",
  },
  {
    Code: "869",
    Text: "Serviços de segurança - programas não classificados noutra área de formação",
    Hint: "Serviços / Serviços de segurança",
  },
  {
    Code: "999",
    Text: "Desconhecido ou não especificado",
    Hint: "",
  },
];

export function GetTypeTextById(id) {
  for (let index = 0; index < Table_30.length; index++) {
    if (Table_30[index].Code === id) {
      return Table_30[index].Text;
    }
  }
  return "";
}
