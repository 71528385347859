export const Text = {
  TypeId: 1,
  Name: "Texto",
};
export const TrueFalse = {
  TypeId: 2,
  Name: "Verdadeiro ou falso",
};
export const Scale = {
  TypeId: 3,
  Name: "Escala",
};

export const SurveyQuestionTypes = [Text, TrueFalse, Scale];

export function GetTypeNameById(id) {
  for (let index = 0; index < SurveyQuestionTypes.length; index++) {
    if (SurveyQuestionTypes[index].TypeId === id) {
      return SurveyQuestionTypes[index].Name;
    }
  }
  return "";
}
