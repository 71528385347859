<template>
  <div>
    <v-select
      v-model="current"
      :items="List"
      item-text="Text"
      item-value="Code"
      @change="onChanged"
      @click:clear="clearData"
      clearable
    >
      <template slot="selection" slot-scope="data">
        {{ data.item.Code }} - {{ data.item.Text }}
      </template>
      <template slot="item" slot-scope="data" two-line>
        <v-list-tile-content>
          <v-list-tile-title>
            {{ data.item.Code }} - {{ data.item.Text }}
          </v-list-tile-title>
        </v-list-tile-content>
      </template>
    </v-select>
    <v-alert :value="true" type="warning" outline v-if="hint">
      {{ this.hint }}
    </v-alert>
  </div>
</template>
<script>
export default {
  props: ["value", "items", "table"],
  data() {
    return {
      current: this.value,
      hint: "",
      info: {},
      List: this.items,
    };
  },
  methods: {
    clearData() {
      this.hint = "";
      this.$emit("remove", this.table);
    },
    onChanged(e) {
      this.current = e;
      var hint = this.items.find((s) => s.Code === e).Hint
        ? this.items.find((s) => s.Code === e).Hint
        : "";
      if (!hint) this.hint = "";
      else {
        this.hint = hint;
      }
      this.info = { Code: this.table, Value: e };
      this.$emit("input", this.info);
    },
  },
};
</script>
