<template>
  <div>
    <v-container>
      <Grid
        :style="{ height: 'auto' }"
        :data-items="Items"
        :columns="Columns"
        :pageable="true"
        :skip="skip"
        :take="take"
        :sortable="true"
        :sort="grid.sort"
        @sortchange="sortChange"
        :total="grid.pagging.total"
        @pagechange="pageChange"
        @remove="remove"
        :filterable="false"
        :filter="grid.filter"
        @filterchange="filterChange"
      >
        <template slot="userCell" slot-scope="{ props }">
          <td :class="props.className" v-if="$store.state.User.IdUserRole > 3">
            <router-link :to="`/users/${props.dataItem.IdUser}`">
              {{ props.dataItem.User[props.field.split(".")[1]] }}
            </router-link>
          </td>
          <td :class="props.className" v-else>
            {{ props.dataItem.User[props.field.split(".")[1]] }}
          </td>
        </template>
        <template slot="CommentCell" slot-scope="{ props }">
          <td>
            <div style="white-space: pre-line">
              {{ props.dataItem.Comment }}
            </div>
          </td>
        </template>
        <GridNoRecords>Não existem comentários</GridNoRecords>
      </Grid>
    </v-container>
  </div>
</template>

<script>
import StringFilterCell from "@/components/grid/string-filter.js";
import * as kendo from "@progress/kendo-ui";
import { notificationServiceBus } from "@/services/bus/notificationServiceBus";
import DeleteCommandCell from "@/components/grid/delete-command.js";
// import EditCommandCell from "@/components/grid/edit-command.js";
import GridHelper from "@/utilities/grid";
import courseCommentsService from "@/services/api/courseCommentsService";

export default {
  props: {
    course: { type: Object, required: true },
  },
  data() {
    return {
      grid: {
        sort: [],
        filter: { logic: "and", filters: [] },
        pagging: {
          pageId: 1,
          pageSize: 10,
          total: 0,
        },
      },
      isLoading: false,
      courseClassSessions: [],
      Items: [],
    };
  },
  computed: {
    skip() {
      return (this.grid.pagging.pageId - 1) * this.grid.pagging.pageSize;
    },
    take() {
      return this.grid.pagging.pageSize;
    },
    Columns() {
      let cols = [
        {
          field: "DateCreated",
          title: "Data",
          filterable: false,
          sortable: true,
        },
        {
          field: "User.Name",
          title: "Aluno",
          cell: "userCell",
          sortable: false,
          filterCell: StringFilterCell,
        },
        {
          field: "Comment",
          title: "Comentário",
          cell: "CommentCell",
          filterable: false,
          sortable: false,
        },
        {
          cell: DeleteCommandCell,
          filterable: false,
          sortable: false,
          width: 75,
          title: "",
        },
      ];

      return cols;
    },
  },
  async created() {
    await this.loadItems();
  },
  methods: {
    pageChange(ev) {
      this.grid.pagging.pageId = ev.page.skip / ev.page.take + 1;
      this.loadItems();
    },
    async loadItems() {
      const options = GridHelper.ConvertOptionsFromNativeKendoUIGrid(
        this.grid.pagging,
        this.grid.filter,
        this.grid.sort
      );

      if (this.course) {
        options.Filters.push({
          Field: "IdCourse",
          Operator: "eq",
          Value: this.course.IdCourse,
        });
      }

      if (this.user) {
        options.Filters.push({
          Field: "IdUser",
          Operator: "eq",
          Value: this.user.IdUser,
        });
      }

      await courseCommentsService.Get(options).then((res) => {
        this.Items = res.data.Items;
        this.grid.pagging.total = res.data.Total;
      });
    },
    filterChange(ev) {
      this.grid.pagging.pageId = 1;
      this.grid.filter = ev.filter;
      this.loadItems();
    },
    remove(item) {
      var id = item.dataItem.IdCourseComment;
      kendo.confirm("Tem a certeza que quer apagar o comentário?").done(() => {
        courseCommentsService
          .Delete(id)
          .then(() => {
            notificationServiceBus.showSuccessMessage(
              "Successo!",
              "Comentário apagado com sucesso!"
            );
            this.loadItems();
          })
          .catch((error) => {
            notificationServiceBus.showError(error.response);
          });
      });
    },
    sortChange(ev) {
      this.grid.sort = ev.sort;
      this.grid.pagging.pageId = 1;
      this.loadItems();
    },
  },
};
</script>

<style scoped></style>
