<template>
  <div class="container__custom--w800">
    <v-layout row>
      <v-flex xs12>
        <v-form @submit.prevent="saveInstance">
          <v-card>
            <v-card-title primary-title>
              <h3 class="headline mb-0">Alteração de dados do inquérito</h3>
            </v-card-title>
            <v-card-text>
              <v-text-field
                label="Nome do inquérito"
                v-model="Instance.Name"
                :error="$v.Instance.Name.$error"
                @blur="$v.Instance.Name.$touch()"
                required
              ></v-text-field>
              <v-text-field
                label="Sub-titulo"
                v-model="Instance.Subtitle"
                :error="$v.Instance.Name.$error"
                @blur="$v.Instance.Name.$touch()"
                required
              ></v-text-field>
              <v-select
                label="Tipo de inquérito"
                v-model="Instance.TypeId"
                item-text="Name"
                item-value="TypeId"
                :items="types"
              >
              </v-select>
              <v-text-field
                label="Pontos para gamification"
                type="number"
                v-model="Instance.PointsForGamification"
                :error="$v.Instance.PointsForGamification.$error"
                @blur="$v.Instance.PointsForGamification.$touch()"
                required
              ></v-text-field>
            </v-card-text>
            <v-card-actions>
              <v-btn
                :loading="isSaving"
                type="submit"
                color="accent"
                class="ml-auto"
                >Confirmar</v-btn
              >
            </v-card-actions>
          </v-card>
        </v-form>
      </v-flex>
    </v-layout>
  </div>
</template>
<script>
import { required, minValue } from "vuelidate/lib/validators";
import { SurveyTypes } from "@/models/surveys-types.js";
import { notificationServiceBus } from "@/services/bus/notificationServiceBus";
import surveyService from "@/services/api/surveyService";

export default {
  props: { survey: { type: Object, default: null } },
  components: {},
  data() {
    return {
      types: SurveyTypes,
      Instance: null,
      isSaving: false,
    };
  },
  validations: {
    Instance: {
      Name: {
        required,
      },
      PointsForGamification: {
        required,
        minValue: minValue(1),
      },
    },
  },
  computed: {},
  created() {
    this.Instance = { ...this.survey };
  },
  watch: {},
  methods: {
    async saveInstance() {
      await surveyService
        .Update(this.Instance)
        .then(() => {
          notificationServiceBus.showSuccessMessage(
            "Successo!",
            "Alterou os dados com sucesso!"
          );
        })
        .catch((error) => {
          notificationServiceBus.showErrorMessage(
            "Erro!",
            "Erro a editar os dados!" + error.response
          );
        });
    },
  },
};
</script>
