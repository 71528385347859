<template>
  <div class="container__custom--w800">
    <v-form v-if="Instance" @submit.prevent="saveInstance">
      <v-card>
        <v-card-title>
          <h3 class="title" primary>Editar</h3>
        </v-card-title>
        <v-card-text class="custom-grid">
          <v-layout wrap row>
            <v-flex xs12>
              <v-text-field
                v-model="Instance.Name"
                :error="$v.Instance.Name.$error"
                @blur="$v.Instance.Name.$touch()"
                label="Nome"
                required
              ></v-text-field>
            </v-flex>
            <v-flex xs6>
              <v-select
                v-model="Instance.TypeId"
                :items="types"
                label="Tipo"
                item-text="Name"
                item-value="TypeId"
                required
              ></v-select>
            </v-flex>
            <v-flex xs6>
              <v-select
                v-model="Instance.IdPortfolioCategory"
                :items="Categories"
                label="Categoria"
                item-text="Name"
                item-value="IdPortfolioCategory"
                :clearable="true"
              ></v-select>
            </v-flex>
            <v-flex xs12>
              <v-select
                v-model="Instance.UserGroupsIds"
                :items="userGroups"
                label="Grupo(s) de Utilizador"
                item-text="Name"
                item-value="IdUserGroup"
                value="IdUserGroup"
                multiple
              ></v-select>
            </v-flex>
            <v-flex xs6>
              <app-date-picker
                v-model="Instance.DateStart"
                title="Data Início"
                required
              ></app-date-picker>
            </v-flex>
            <v-flex xs6>
              <app-date-picker
                v-model="Instance.DateEnd"
                title="Data Fim"
                required
              ></app-date-picker>
            </v-flex>
            <v-flex xs6>
              <v-select
                v-model="Instance.IdUserManager"
                :items="Users"
                label="Gestor"
                item-text="Name"
                item-value="IdUser"
                :clearable="true"
              ></v-select>
            </v-flex>
            <v-flex xs6>
              <v-select
                v-model="Instance.IdUserResponsible"
                :items="Users"
                label="Responsável"
                item-text="Name"
                item-value="IdUser"
                :clearable="true"
              ></v-select>
            </v-flex>
          </v-layout>
          <v-layout>
            <v-flex xs6>
              <v-autocomplete
                v-model="Instance.IdJobDepartment"
                :items="Departments"
                label="Departamento"
                item-text="Name"
                item-value="IdJobDepartment"
                :clearable="true"
              ></v-autocomplete>
            </v-flex>
            <v-flex xs6>
              <v-autocomplete
                :clearable="true"
                v-model="Instance.IdJobCostCenter"
                :items="JobCostCenters"
                label="Centro de custo"
                item-text="Name"
                item-value="IdJobCostCenter"
              ></v-autocomplete>
            </v-flex>
          </v-layout>
        </v-card-text>
        <v-card-actions>
          <v-btn
            type="submit"
            color="accent"
            class="ml-auto"
            :loading="isLoading"
            :disabled="$v.Instance.$error"
            >Confirmar</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-form>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import * as types from "@/store/types";
import { PortfolioTypes } from "@/models/portfolio-types";
import { required, minLength } from "vuelidate/lib/validators";
import { notificationServiceBus } from "@/services/bus/notificationServiceBus";
import portfoliosService from "@/services/api/portfoliosService";
import DatePickerComponent from "@/components/forms/date-picker";
import jobDepartmentsService from "@/services/api/jobDepartmentsService";

export default {
  components: {
    "app-date-picker": DatePickerComponent,
  },
  props: {
    portfolio: { type: Object },
  },
  validations: {
    Instance: {
      Name: {
        required,
        minLength: minLength(4),
      },
    },
  },
  computed: {
    ...mapGetters({
      userGroups: types.GET_USER_GROUPS,
    }),
  },
  data() {
    return {
      Users: null,
      Categories: null,
      JobCostCenters: null,
      SelectedUserGroups: [],
      Departments: null,
      Managers: null,
      Directors: null,
      Instance: null,
      isLoading: false,
      types: PortfolioTypes,
    };
  },
  async created() {
    this.Instance = { ...this.portfolio };
    delete this.readPortfolioRelatedData();
    await this.readDepartments();
    this.SelectedUserGroups = Object.keys(this.Instance.UserGroups).map(
      (i) => this.Instance.UserGroups[i].IdUserGroup
    );
  },
  methods: {
    async readDepartments() {
      await jobDepartmentsService
        .Get()
        .then((res) => {
          this.Departments = res.data.Items;
        })
        .catch((error) => {
          this.Departments = [];
          notificationServiceBus.showError(error.response);
        });
    },
    async readPortfolioRelatedData() {
      await portfoliosService
        .GetRelatedData(this.portfolio.IdPortfolio)
        .then((res) => {
          this.Categories = res.data.Categories;
          this.JobCostCenters = res.data.CostCenters;
          this.Users = res.data.Users;
        })
        .catch((error) => {
          notificationServiceBus.showError(error.response);
        });
    },
    async saveInstance() {
      if (!this.isLoading) {
        this.isLoading = true;
        await portfoliosService
          .Update(this.Instance)
          .then(() => {
            notificationServiceBus.showSuccessMessage(
              "Successo!",
              "Editou o portfolio com sucesso!"
            );
            this.isLoading = false;
            this.$emit("portfolioEdited");
          })
          .catch((error) => {
            this.isLoading = false;
            notificationServiceBus.showError(error.response);
          });
      }
    },
  },
};
</script>

<style scoped></style>
