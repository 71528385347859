<template>
  <section id="CatalogCalendarIndex">
    <v-dialog
      persistent
      v-model="dialog"
      max-width="800"
      v-if="this.selectedEvent != null"
    >
      <v-card>
        <v-card-title class="headline">
          <v-layout row wrap>
            <v-flex xs12>
              <h3>{{ this.selectedEvent.description }}</h3>
            </v-flex>
            <v-flex xs12>
              <p class="size--s16">{{ this.selectedEvent.title }}</p>
            </v-flex>
          </v-layout>
        </v-card-title>
        <v-card-text>
          <v-layout
            row
            wrap
            v-if="!isValidHttpUrl(this.selectedEvent.location)"
          >
            <v-flex xs12 sm6>
              <h4>Local: {{ this.selectedEvent.location }}</h4>
              <div v-if="this.selectedEvent.formers.length > 0">
                Formadores:
                <p
                  v-for="(former, index) in this.selectedEvent.formers"
                  :key="`event-${index}`"
                >
                  {{ former.Name }}
                </p>
              </div>
              <div v-else>
                <p>Não tem formadores atribuidos</p>
              </div>
            </v-flex>
            <v-flex xs12 sm6>
              <h4>
                Data de inicio:
                <datetime-presenter
                  :value="this.selectedEvent.start"
                ></datetime-presenter>
              </h4>
              <h4>
                Data de Fim:
                <datetime-presenter
                  :value="this.selectedEvent.end"
                ></datetime-presenter>
              </h4>
            </v-flex>
          </v-layout>
          <div v-else>
            <h4 class="mb-3">
              Local:
              <a :href="this.selectedEvent.location"
                >Clique aqui para aceder à sessão online.</a
              >
            </h4>
            <div v-if="this.selectedEvent.formers.length > 0">
              Formadores:
              <p
                v-for="(former, index) in this.selectedEvent.formers"
                :key="`event-${index}`"
              >
                {{ former }}
              </p>
            </div>
            <div v-else>
              <p>Não tem formadores atribuidos</p>
            </div>
            <v-layout row wrap class="mt-3">
              <v-flex xs12 sm6>
                <h4>
                  Data de inicio:
                  <datetime-presenter
                    :value="this.selectedEvent.start"
                  ></datetime-presenter>
                </h4>
              </v-flex>
              <v-flex xs12 sm6>
                <h4>
                  Data de Fim:
                  <datetime-presenter
                    :value="this.selectedEvent.end"
                  ></datetime-presenter>
                </h4>
              </v-flex>
            </v-layout>
          </div>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-text>
          <div v-if="this.selectedEvent.courseClass != null">
            <v-layout row wrap>
              <v-flex xs12 sm6>Sessão:</v-flex>
              <v-flex xs12 sm6
                ><v-btn
                  color="primary"
                  :href="'/catalog/courses/' + this.selectedEvent.idCourse"
                  >Detalhe do Formação</v-btn
                ></v-flex
              >
            </v-layout>
            <v-layout row wrap>
              <v-flex xs12 sm6>
                <p>
                  {{ this.selectedEvent.title }}
                </p>
              </v-flex>
            </v-layout>
          </div>
          <div v-else>
            <v-layout row wrap>
              <v-flex xs12 sm6
                ><v-btn
                  color="primary"
                  :href="'/catalog/courses/' + this.selectedEvent.idCourse"
                  >Detalhe do Formação</v-btn
                ></v-flex
              >
            </v-layout>
          </div>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="secondary" flat @click="dialog = false">Fechar</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <app-page-title>
      <h1>Calendário</h1>
    </app-page-title>
    <v-container grid-list-md text-xs-center>
      <v-layout row wrap>
        <v-flex xs12 sm3>
          <course-selector v-model="IdCourse"></course-selector>
        </v-flex>
        <v-flex xs12 sm3>
          <job-locations-selector
            v-model="IdJobLocation"
          ></job-locations-selector>
        </v-flex>
      </v-layout>
      <kendo-scheduler
        id="Scheduler"
        :data-source="events"
        :views="views"
        :height="800"
        :mobile="true"
        :editable="false"
        :selectable="true"
        :event-template="eventTemplate"
        :change="OnCellChange"
      ></kendo-scheduler>
    </v-container>
  </section>
</template>
<script>
import { notificationServiceBus } from "@/services/bus/notificationServiceBus";
import coursesService from "@/services/api/coursesService";
import Datetimepresenter from "@/components/presenters/datetime";
import JobLocationsComponent from "@/components/selectors/job-locations";
import CourseSelectorComponent from "@/components/selectors/courses";
import Dates from "@/utilities/dates";

export default {
  components: {
    "datetime-presenter": Datetimepresenter,
    "job-locations-selector": JobLocationsComponent,
    "course-selector": CourseSelectorComponent,
  },
  data() {
    return {
      selectedEvent: null,
      IdCourse: 0,
      IdJobLocation: 0,
      IdJobLocationGroup: 0,
      dialog: false,
      eventTemplate:
        '<div class="calendar-template"><strong>#: title #</strong> - #: description #</div>',
      views: [
        { type: "month", selected: true },
        { type: "week" },
        { type: "day" },
      ],
      events: [],
    };
  },
  computed: {},
  watch: {
    IdCourse: async function (val) {
      await coursesService
        .Calendar({ IdCourse: val })
        .then((res) => {
          this.events = this.events = res.data.Items.map((item) => {
            return {
              ID: item.Id,
              title: item.Title,
              description: item.Description,
              start: Dates.ConvertToDate(item.Start),
              end: Dates.ConvertToDate(item.End),
              location: item.Location,
              formers: item.Formers,
              users: item.Users,
              idCourse: item.IdCourse,
              isAllDay: item.Start === item.End,
              courseClass: item.Class,
              classes: item.Classes,
            };
          });
        })
        .catch((error) => {
          notificationServiceBus.showError(error.response);
        });
    },
    IdJobLocation: async function (val) {
      await coursesService
        .Calendar({ IdJobLocation: val })
        .then((res) => {
          this.events = this.events = res.data.Items.map((item) => {
            return {
              ID: item.Id,
              title: item.Title,
              description: item.Description,
              start: Dates.ConvertToDate(item.Start),
              end: Dates.ConvertToDate(item.End),
              location: item.Location,
              formers: item.Formers,
              users: item.Users,
              idCourse: item.IdCourse,
              isAllDay: item.Start === item.End,
              courseClass: item.Class,
              classes: item.Classes,
            };
          });
        })
        .catch((error) => {
          notificationServiceBus.showError(error.response);
        });
    },
    IdJobLocationGroup: async function (val) {
      await coursesService
        .Calendar({ IdJobLocationGroup: val })
        .then((res) => {
          this.events = this.events = res.data.Items.map((item) => {
            return {
              ID: item.Id,
              title: item.Title,
              description: item.Description,
              start: Dates.ConvertToDate(item.Start),
              end: Dates.ConvertToDate(item.End),
              location: item.Location,
              formers: item.Formers,
              users: item.Users,
              idCourse: item.IdCourse,
              isAllDay: item.Start === item.End,
              courseClass: item.Class,
              classes: item.Classes,
            };
          });
        })
        .catch((error) => {
          notificationServiceBus.showError(error.response);
        });
    },
  },
  methods: {
    OnCellChange(ev) {
      ev.preventDefault();
      var evs = ev.events;
      if (evs.length > 0) {
        this.selectedEvent = evs[0];
        this.dialog = true;
      } else {
        this.dialog = false;
      }
    },

    isValidHttpUrl(string) {
      let url;
      try {
        url = new URL(string);
      } catch (_) {
        return false;
      }

      return url.protocol === "http:" || url.protocol === "https:";
    },
    async loadEvents() {
      await coursesService
        .Calendar()
        .then((res) => {
          this.events = this.events = res.data.Items.map((item) => {
            return {
              ID: item.Id,
              title: item.Title,
              description: item.Description,
              start: Dates.ConvertToDate(item.Start),
              end: Dates.ConvertToDate(item.End),
              location: item.Location,
              formers: item.Formers,
              users: item.Users,
              idCourse: item.IdCourse,
              isAllDay: item.Start === item.End,
              courseClass: item.Class,
              classes: item.Classes,
            };
          });
        })
        .catch((error) => {
          notificationServiceBus.showError(error.response);
        });
    },
  },
  async created() {
    await this.loadEvents();
  },
};
</script>

<style lang="scss">
.my-event {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  border-radius: 2px;
  background-color: #1867c0;
  color: #ffffff;
  border: 1px solid #1867c0;
  width: 100%;
  padding: 3px;
  margin-bottom: 1px;
}
.k-event {
  height: 25px !important;
  .calendar-template {
    strong {
      font-size: 12px;
    }
    small {
      display: block;
      font-size: 10px;
      line-height: 12px;
    }
  }
}
</style>
