<template>
  <section id="CategoriesIndex">
    <app-page-title>
      <h1>Listagem de Categorias</h1>
    </app-page-title>
    <v-container>
      <app-categories-grid :dataItem="null"></app-categories-grid>
    </v-container>
  </section>
</template>

<script>
import Vue from "vue";
import CategoriesGridComponent from "./grid";
Vue.component("app-categories-grid", CategoriesGridComponent);

export default {
  components: {},
};
</script>

<style></style>
