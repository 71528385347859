export const Table_34 = [
  {
    Code: "01",
    Text: "Própria empresa",
    Hint: `A entidade formadora é a própria empresa sempre que a organização e concepção da formação (conteúdo programático, estrutura curricular) seja da responsabilidade 
      desta (independentemente do local onde a formação decorreu, isto é, em instalações pertencentes à empresa ou a outras entidades).`,
  },
  {
    Code: "02",
    Text: "Centro de Emprego e/ou de Formação Profissional de Gestão Direta",
    Hint: "Considere a formação organizada pelos centros de formação que funcionam na estrita dependência do Instituto de Emprego e Formação Profissional (IEFP).",
  },
  {
    Code: "03",
    Text: "Centro de Formação Profissional de Gestão Participada (Centros Protocolares)",
    Hint: "Considere a formação organizada pelos centros de formação cuja criação resultou de um protocolo firmado entre uma entidade privada ou pública e o IEFP e cujo funcionamento é assegurado pelas partes, nos termos do acordo firmado.",
  },
  {
    Code: "04",
    Text: "Associações de Empregadores ou Outras Associações Empresariais",
    Hint: "Considere a formação organizada pelas associações de empregadores de um ou vários sectores de actividade.",
  },
  {
    Code: "05",
    Text: "Associações Sindicais ou Ordens Profissionais",
    Hint: "Considere a formação organizada pelas associações de trabalhadores ou profissionais liberais.",
  },
  {
    Code: "06",
    Text: "Escolas/Universidades",
    Hint: "Considere a formação organizada por escolas, universidades ou outros estabelecimentos de ensino públicos ou privados.",
  },
  {
    Code: "07",
    Text: "Empresas de formação",
    Hint: "Considere a formação organizada por empresas cuja principal actividade é a prestação de cursos de formação profissional.",
  },
  {
    Code: "08",
    Text: "Empresas privadas cuja atividade principal não é a formação",
    Hint: "Considere a formação organizada e prestada por empresas cuja atividade principal não é a prestação de cursos de formação profissional (por exemplo, fornecedores de equipamento, empresas-mãe, empresas associadas).",
  },
  {
    Code: "09",
    Text: "Outro tipo de entidade",
    Hint: "",
  },
  {
    Code: "99",
    Text: "Desconhecida",
    Hint: "",
  },
];

export function GetTypeTextById(id) {
  for (let index = 0; index < Table_34.length; index++) {
    if (Table_34[index].Code === id) {
      return Table_34[index].Text;
    }
  }
  return "";
}
