<template>
  <kendo-dropdownlist
    v-model="current"
    :data-source="Items"
    :data-text-field="'Name'"
    :data-value-field="'TypeId'"
    :optionLabel="label"
    @select="onChanged"
  />
</template>
<script>
import { CourseTypes } from "@/models/course-types";

export default {
  props: ["value"],
  watch: {
    value() {
      this.current = this.value;
    },
  },
  data() {
    return {
      current: this.value,
      label: {
        TypeId: null,
        Name: "Tipo de Formação...",
      },
      Items: CourseTypes,
    };
  },
  methods: {
    onChanged(e) {
      this.$emit("input", e.dataItem.TypeId);
    },
  },
};
</script>
