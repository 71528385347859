<template>
  <section id="CategoriesIndex">
    <app-page-title>
      <div v-if="survey">
        <h1 class="size--s25 white--text">{{ survey.Name }}</h1>
      </div>
      <div v-show="!isEditView" class="text-xs-right">
        <dropdown
          v-if="courses != null"
          :options="courses"
          :selected="course"
          placeholder="Pré-visualizar"
          v-on:updateOption="onSelect"
        ></dropdown>

        <v-btn color="info" :to="'edit'">
          Editar
          <i class="icon-arrow-right ml-2"></i>
        </v-btn>
      </div>
    </app-page-title>
    <div class="d-flex align-content-stretch detail-wrapper">
      <div class="detail-sidebar">
        <app-sidebar-menu :menus="menus" />
      </div>
      <div class="detail-content">
        <router-view
          v-if="survey"
          params="route: route"
          @surveyEdited="LoadSurvey"
          :survey="survey"
        />
      </div>
    </div>
  </section>
</template>

<script>
import { notificationServiceBus } from "@/services/bus/notificationServiceBus";
import surveyService from "@/services/api/surveyService";
import coursesService from "@/services/api/coursesService";
import SidebarComponent from "@/components/layout/page-menu";
import { FRONTEND_URL } from "@/settings";
import dropdown from "vue-dropdowns";

export default {
  components: {
    "app-sidebar-menu": SidebarComponent,
    dropdown: dropdown,
  },
  data() {
    return {
      survey: null,
      menus: [
        {
          Name: "Dashboard",
          Icon: "icon-home",
          Path: "dashboard",
        },
        {
          Name: "Perguntas",
          Icon: "icon-list",
          Path: "questions",
        },
      ],
      courses: null,
      course: {},
    };
  },
  watch: {
    "$route.params.idsurvey"() {
      this.LoadSurvey();
    },
  },
  computed: {
    isNew() {
      return this.Instance != null && this.Instance.IdSurvey == 0;
    },
    isEditView() {
      return this.$route.path.indexOf("/edit") >= 0;
    },
  },

  methods: {
    onSelect(payload) {
      this.course = payload;
      var url = this.GetFrontEndUrl(this.course.idCourse);
      window.open(url, "_blank");
    },
    GetFrontEndUrl: function (courseId) {
      // /courses/detail/1/survey-avaliation
      var url = FRONTEND_URL + "/courses/detail/" + courseId;
      if (this.survey.TypeId == 2) {
        return url + "/survey-avaliation";
      }
      if (this.survey.TypeId == 1) {
        return url + "/survey-satisfaction";
      }
      if (this.survey.TypeId == 3) {
        return url + "/survey-formation";
      }
    },
    async LoadSurvey() {
      var id = this.$route.params.idsurvey;
      await surveyService
        .GetSingle(id)
        .then((response) => {
          this.survey = response.data.Instance;
        })
        .catch(() => {
          notificationServiceBus.showErrorMessage(
            "Erro!",
            "ocorreu um erro inesperado! Tente novamente."
          );
        });
    },
    async LoadCourses() {
      await coursesService
        .GetBySurvey(this.survey.IdSurvey)
        .then((res) => {
          this.courses = res.data;
        })
        .catch((error) => {
          notificationServiceBus.showError(error.response);
        });
    },
  },
  async created() {
    await this.LoadSurvey();
    await this.LoadCourses();
  },
};
</script>

<style>
.btn-group {
  text-align: center;
  background: #e1e1e1;
  margin-right: 20px;
  padding: 0px 20px;
}
</style>
