export const Table_35 = [
  {
    Code: "01",
    Text: "Diploma de qualificação",
    Hint: `Diploma que corresponde à obtenção de uma qualificação prevista no Catálogo Nacional de Qualificações. 
      Deve referenciar o nível de qualificação correspondente, de acordo com o Quadro Nacional de Qualificações, e quando aplicável, 
      a atividade profissional para a qual foi obtida qualificação.`,
  },
  {
    Code: "02",
    Text: "Certificado de qualificação",
    Hint: `Certificado que corresponde à conclusão com aproveitamento de uma ou mais unidades de formação desenvolvidas com base nos referenciais do 
      Catálogo Nacional de Qualificações, que não permite de imediato a obtenção de qualificação ou a conclusão de um processo de reconhecimento, 
      validação e certificação de competências.`,
  },
  {
    Code: "03",
    Text: "Certificado de formação profissional certificada",
    Hint: "Certificado que corresponde à conclusão com aproveitamento de uma ação de formação certificada (ministrada por entidade formadora certificada), não inserida no Catálogo Nacional de Qualificações.",
  },
  {
    Code: "04",
    Text: "Certificado de formação profissional não certificada",
    Hint: "Certificado que corresponde à conclusão com aproveitamento de uma Ação de formação contínua realizada por uma entidade formadora não certificada.",
  },
  {
    Code: "05",
    Text: "Sem certificado por reprovação",
    Hint: "Corresponde à frequência de qualquer Ação que dê origem a certificado ou diploma, tendo-se verificado reprovação.",
  },
  {
    Code: "06",
    Text: "Certificado de frequência",
    Hint: "Não pressupõe a conclusão com aproveitamento, i.e, com avaliação.",
  },
  {
    Code: "07",
    Text: "Diploma de ensino superior",
    Hint: "Documento oficial comprovativo da atribuição de um nível, de grau académico ou da conclusão de um curso não conferente de grau emitido por um estabelecimento de ensino (por exemplo licenciatura, pós-graduação, mestrado, doutoramento).",
  },
  {
    Code: "08",
    Text: "Não aplicável",
    Hint: "Não enquadrável nas situações anteriores, isto é, formação que não confere certificado ou diploma.",
  },
  {
    Code: "99",
    Text: "Desconhecido",
    Hint: "",
  },
];

export function GetTypeTextById(id) {
  for (let index = 0; index < Table_35.length; index++) {
    if (Table_35[index].Code === id) {
      return Table_35[index].Text;
    }
  }
  return "";
}
