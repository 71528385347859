<template>
  <v-dialog v-model="isModalOpen" persistent max-width="600px">
    <v-form @submit.prevent="saveInstance">
      <v-card>
        <v-card-title>
          <span class="headline">{{ title }}</span>
        </v-card-title>
        <v-card-text>
          <div class="form-group">
            <v-text-field
              v-model="Instance.Name"
              @blur="$v.Instance.Name.$touch()"
              label="Nome do Módulo"
              required
            ></v-text-field>
            <v-textarea
              v-model="Instance.Description"
              label="Descrição do Módulo"
            ></v-textarea>
          </div>
        </v-card-text>
        <v-card-actions>
          <v-btn color="white" type="button" @click.prevent="closeAndReset"
            >Cancelar</v-btn
          >
          <v-btn
            :disabled="$v.Instance.$error || !Instance.Name"
            :loading="isLoading"
            color="accent"
            class="ml-auto"
            type="submit"
            >Guardar</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-form>
  </v-dialog>
</template>

<script>
import { required, minLength } from "vuelidate/lib/validators";
import { notificationServiceBus } from "@/services/bus/notificationServiceBus";
import courseModulesService from "@/services/api/courseModulesService";

export default {
  props: ["module"],
  validations: {
    Instance: {
      Name: {
        required,
        minLength: minLength(4),
      },
    },
  },
  watch: {
    module(val) {
      this.Instance = { ...val };
    },
  },
  data() {
    return {
      isModalOpen: false,
      isLoading: false,
      Instance: {},
    };
  },
  created() {
    if (!this.module) {
      this.resetInstance();
    } else {
      this.Instance = { ...this.module };
    }
  },
  computed: {
    isNew() {
      return this.Instance.IdCourseModule === 0;
    },
    title() {
      return `${this.isNew ? "Adicionar" : "Editar"} Módulo`;
    },
  },
  methods: {
    resetInstance() {
      this.Instance = {
        IdCourseModule: 0,
        IdCourse: this.$route.params.idCourse,
        Name: "",
        Description: "",
        OrderId: 1,
      };
    },
    async saveInstance() {
      if (!this.isLoading) {
        this.isLoading = true;
        if (this.isNew) {
          await courseModulesService
            .Create(this.Instance, {})
            .then((response) => {
              this.closeAndReset();
              notificationServiceBus.showSuccessMessage(
                "Successo!",
                "Operação realizada com sucesso!"
              );
              this.resetInstance();
              this.$emit("onCreated", response.data.Instance);
            })
            .catch((error) => {
              this.isLoading = false;
              notificationServiceBus.showError(error.response);
            });
        } else {
          await courseModulesService
            .Update(this.Instance, {})
            .then((response) => {
              this.closeAndReset();
              notificationServiceBus.showSuccessMessage(
                "Successo!",
                "Operação realizada com sucesso!"
              );
              this.resetInstance();
              this.$emit("onEdited", response.data.Instance);
            })
            .catch((error) => {
              this.isLoading = false;
              notificationServiceBus.showError(error.response);
            });
        }
      }
    },
    open() {
      this.isModalOpen = true;
    },
    close() {
      this.isModalOpen = false;
    },
    closeAndReset() {
      this.isLoading = false;
      this.isModalOpen = false;
    },
    edit(item) {
      this.Instance = item;
      this.isModalOpen = true;
    },
  },
};
</script>

<style scoped></style>
