<template>
  <v-layout row wrap>
    <v-flex xs12>
      <app-form-modal
        :title-dialog="'Associar Formação'"
        :titleButton="'Associar Formação'"
        :allowButton="false"
        :confirmAction="savePortfolio"
        :formValid="selectedCourse > 0"
        ref="modal"
      >
        <!-- <v-autocomplete
          v-model="selectedCourse"
          :items="availableCourses"
          label="Seleccione um Formação pelo respetivo código"
          item-text="Code"
          item-value="IdCourse"
        ></v-autocomplete> -->
        <v-autocomplete
          v-model="selectedCourse"
          :items="availableCoursesFilters"
          label="Seleccione um Formação pelo nome"
          item-text="Description"
          item-value="IdCourse"
        ></v-autocomplete>
        <v-autocomplete
          clearable
          v-model="selectedRestriction"
          :items="restrictionTypes"
          label="Seleccione um restrição"
          item-text="Name"
          item-value="TypeId"
        ></v-autocomplete>
      </app-form-modal>
    </v-flex>
    <v-flex xs12>
      <div class="grid">
        <div class="k-grid k-grid-toolbar">
          <v-btn color="accent" title="Adicionar" @click="addPortfolio"
            >Adicionar</v-btn
          >
        </div>
      </div>
      <Grid
        :id="identifier"
        :style="{ height: 'auto' }"
        :data-items="Items"
        :columns="Columns"
        @remove="remove"
      >
        <GridNoRecords>Não existem Formações associados</GridNoRecords>
      </Grid>
    </v-flex>
  </v-layout>
</template>
<script>
import * as kendo from "@progress/kendo-ui";
import Sortable from "sortablejs";
import FormModalComponent from "@/components/modals/form-modal";
import { notificationServiceBus } from "@/services/bus/notificationServiceBus";
import { PorfolioDurationTypes } from "@/models/portfolio-durations";
import portfoliosService from "@/services/api/portfoliosService";
import { PortfolioRestrictionTypes } from "@/models/portfolio-restriction-types";
import DeleteCommandCell from "@/components/grid/delete-command.js";
import OrderCommandCell from "@/components/grid/order-command.js";

export default {
  props: {
    typeId: { type: Number },
    field: { type: String },
    courses: { type: Array },
    portfolio: { type: Object },
  },
  components: {
    "app-form-modal": FormModalComponent,
  },
  data() {
    return {
      restrictionTypes: PortfolioRestrictionTypes,
      types: PorfolioDurationTypes,
      Items: this.portfolio[this.field],
      Columns: [
        {
          cell: OrderCommandCell,
          filterable: false,
          sortable: false,
          width: 75,
          title: "Ordem",
        },
        {
          field: "Code",
          title: "Código",
          width: 200,
        },
        {
          field: "Name",
          title: "Nome",
        },
        {
          cell: DeleteCommandCell,
          filterable: false,
          sortable: false,
          width: 75,
          title: "",
        },
      ],
      isEditing: false,
      selectedCourse: 0,
      selectedRestriction: 0,
    };
  },
  computed: {
    availableCoursesFilters() {
      return this.availableCourses.map((entry) => {
        const Description = `${entry.Code} » ${entry.Name}`;
        return Object.assign({}, entry, { Description });
      });
    },
    identifier() {
      return `${this.field}_Grid`;
    },
    availableCourses() {
      var array = [];
      if (this.courses) {
        for (let i = 0; i < this.courses.length; i++) {
          var add = true;
          for (let j = 0; j < this.portfolio[this.field].length; j++) {
            if (
              this.portfolio[this.field][j].IdCourse == this.courses[i].IdCourse
            ) {
              add = false;
              break;
            }
          }
          if (add) {
            array.push(this.courses[i]);
          }
        }
      }
      return array;
    },
  },
  methods: {
    reset() {
      this.selectedCourse = 0;
      this.selectedRestriction = 0;
    },
    getSelectedIds() {
      return Object.keys(this.portfolio[this.field]).map(
        (i) => this.portfolio[this.field][i].IdCourse
      );
    },
    addPortfolio() {
      this.$refs.modal.open();
    },
    async savePortfolio(callBack) {
      if (this.selectedCourse <= 0) {
        callBack(false);
        return;
      }
      this.portfolio[this.field].push(
        this.courses.find((s) => s.IdCourse == this.selectedCourse)
      );
      await this.updateCourses(callBack, this.getSelectedIds());
    },
    async updateCourses(callBack, courses) {
      await portfoliosService
        .UpdateCourses(
          this.portfolio.IdPortfolio,
          courses,
          this.typeId,
          this.selectedRestriction
        )
        .then((response) => {
          notificationServiceBus.showSuccessMessage(
            "Successo!",
            "Alterações ao portfolio efetuadas com sucesso!"
          );
          this.reset();
          this.portfolio[this.field] = [...response.data.Courses];
          this.Items = this.portfolio[this.field];
          if (callBack) callBack(true);
        })
        .catch((error) => {
          if (callBack) callBack(false);
          notificationServiceBus.showError(error.response);
        });
    },
    remove(item) {
      kendo.confirm("Tem a certeza que quer apagar o Formação?").done(() => {
        var ids = this.getSelectedIds().filter(
          (s) => s !== item.dataItem.IdCourse
        );
        this.updateCourses(null, ids);
      });
    },
  },
  mounted() {
    var that = this;
    var element = `${this.identifier}-Table`;
    this.$el
      .querySelector(
        `#${this.identifier} > .k-grid-container > .k-grid-content > div > .k-grid-table > tbody`
      )
      .setAttribute("id", element);
    Sortable.create(document.getElementById(element), {
      handle: ".k-action-move",
      animation: 150,
      onEnd: function (evt) {
        var arr = [...that.Items];
        var old = arr[evt.oldIndex];
        arr = arr
          .slice(0, evt.oldIndex)
          .concat(arr.slice(evt.oldIndex + 1, arr.length));
        arr.splice(evt.newIndex, 0, old);
        that.updateCourses(
          null,
          arr.map((s) => s.IdCourse)
        );
        that.Items = [...[]];
      },
    });
  },
};
</script>

<style scoped></style>
