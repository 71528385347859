import { render, staticRenderFns } from "./inscriptions.vue?vue&type=template&id=da97ffc8&scoped=true"
import script from "./inscriptions.vue?vue&type=script&lang=js"
export * from "./inscriptions.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "da97ffc8",
  null
  
)

export default component.exports