<template>
  <div>
    <div class="k-grid k-grid-toolbar">
      <v-btn
        :disabled="isAdding"
        color="accent"
        class="mr-2"
        @click.prevent="onCreateHandler"
        >Adicionar resposta</v-btn
      >
      <v-btn
        :disabled="isAdding"
        color="accent"
        class="ml-2"
        @click.prevent="onCreateDefaultHandler"
        >Adicionar Verdadeiro/Falso</v-btn
      >
    </div>
    <Grid
      ref="answerGrid"
      :style="{ height: 'auto' }"
      :data-items="Items"
      :columns="Columns"
      :edit-field="'inEdit'"
      @itemchange="itemChange"
    >
      <template slot="isCorrectCell" slot-scope="{ props }">
        <td :class="props.className">
          <div v-if="!props.dataItem.inEdit">
            <v-switch
              v-model="props.dataItem.IsCorrect"
              @change="checkCorrect(props.dataItem)"
            ></v-switch>
          </div>
        </td>
      </template>
      <template slot="titleAnswerCell" slot-scope="{ props }">
        <td :class="props.className">
          <div
            v-if="!props.dataItem.inEdit"
            v-html="props.dataItem.Title"
            class="question-title-body"
          ></div>
          <kendo-editor
            v-else
            :resizable-content="false"
            :resizable-toolbar="false"
            v-model="props.dataItem.Title"
            :tools="kendoEditorTools"
            rows="5"
          ></kendo-editor>
        </td>
      </template>
      <template slot="observationAnswerCell" slot-scope="{ props }">
        <td :class="props.className">
          <div
            v-if="!props.dataItem.inEdit"
            v-html="props.dataItem.Observation"
            class="question-title-body"
          ></div>
          <kendo-editor
            v-else
            :resizable-content="false"
            :resizable-toolbar="false"
            v-model="props.dataItem.Observation"
            :tools="kendoEditorTools"
            rows="5"
          ></kendo-editor>
        </td>
      </template>
      <template slot="editCell" slot-scope="{ props }">
        <td :class="props.className">
          <button
            class="k-button k-button-icontext"
            @click="rowEditHandler(props.dataItem)"
          >
            <span
              :class="{
                'icon-mode_edit': true,
                'icon-add':
                  props.dataItem.IdCourseContentQuestionAnswer == 0 && isAdding,
              }"
            ></span>
          </button>
        </td>
      </template>
      <template slot="removeCell" slot-scope="{ props }">
        <td :class="props.className">
          <button
            class="k-button k-button-icontext"
            @click="rowRemoveHandler(props.dataItem)"
          >
            <span class="icon-close"></span>
          </button>
        </td>
      </template>
      <GridNoRecords>Não existem respostas</GridNoRecords>
    </Grid>
  </div>
</template>

<script>
import Vue from "vue";
import * as kendo from "@progress/kendo-ui";
import { notificationServiceBus } from "@/services/bus/notificationServiceBus";
import courseContentQuestionAnswersService from "@/services/api/courseContentQuestionAnswersService";
import { KendoUI } from "@/settings";

export default {
  props: ["question"],
  data() {
    return {
      kendoEditorTools: KendoUI.SimpleRichEditorTools,
      isAdding: false,
      isModalTestOpen: true,
      isLoading: false,
      Items: [],
      editItem: null,
      Columns: [
        {
          field: "IsCorrect",
          title: "Resposta correta",
          cell: "isCorrectCell",
          width: 125,
        },
        { field: "Title", title: "Resposta", cell: "titleAnswerCell" },
        {
          field: "Observation",
          title: "Observações",
          cell: "observationAnswerCell",
        },
        {
          cell: "editCell",
          filterable: false,
          sortable: false,
          width: 75,
          title: "",
        },
        {
          cell: "removeCell",
          filterable: false,
          sortable: false,
          width: 75,
          title: "",
        },
      ],
    };
  },
  methods: {
    checkCorrect(item) {
      this.updateAnswer(item);
    },
    itemChange: function (e) {
      const data = this.Items.slice();
      const index = data.findIndex(
        (d) =>
          d.IdCourseContentQuestionAnswer ===
          e.dataItem.IdCourseContentQuestionAnswer
      );
      data[index] = { ...data[index], [e.field]: e.value };
      this.Items = data;
      Vue.set(e.dataItem, e.field, e.value);
      this.editItem = { ...e.dataItem };
    },
    onCreateHandler() {
      if (this.isAdding) return;
      this.isAdding = true;
      const newRecord = {
        Title: "",
        inEdit: true,
        IdCourseContentQuestionAnswer: 0,
        IdCourseContentQuestion: this.question.IdCourseContentQuestion,
        OrderId: this.Items.length + 1,
      };
      this.Items.unshift(newRecord);
      this.editItem = newRecord;
    },

    onCreateDefaultHandler() {
      if (this.isAdding) return;
      this.isAdding = true;
      const newRecord2 = {
        Title: "Falso",
        inEdit: true,
        IdCourseContentQuestionAnswer: 0,
        IdCourseContentQuestion: this.question.IdCourseContentQuestion,
        OrderId: this.Items.length + 1,
      };
      this.Items.unshift(newRecord2);
      this.editItem = newRecord2;
      this.createAnswer(this.editItem);

      const newRecord = {
        Title: "Verdadeiro",
        inEdit: true,
        IdCourseContentQuestionAnswer: 0,
        IdCourseContentQuestion: this.question.IdCourseContentQuestion,
        OrderId: this.Items.length + 1,
      };
      this.Items.unshift(newRecord);
      this.editItem = newRecord;
      this.createAnswer(this.editItem);
    },
    onResetAnswers() {
      this.editItem = null;
      this.editOriginal = null;
      this.isAdding = false;
      this.isLoading = false;
    },
    async rowEditHandler(dataItem) {
      if (this.isLoading) {
        return;
      }
      var item = { ...dataItem };
      delete item.inEdit;
      if (!this.isAdding && this.editItem) {
        await this.updateAnswer(item);
        return;
      }
      if (this.isAdding) {
        await this.createAnswer(dataItem);
      } else {
        dataItem.inEdit = true;
        this.editItem = dataItem;
      }
    },
    async rowRemoveHandler(dataItem) {
      if (this.isLoading) {
        return;
      }
      if (
        !this.isAdding &&
        dataItem.IdCourseContentQuestionAnswer > 0 &&
        !dataItem.inEdit
      ) {
        await this.deleteAnswer(dataItem);
      } else {
        this.loadAnswers();
        this.onResetAnswers();
      }
    },
    async createAnswer(item) {
      this.isLoading = true;
      courseContentQuestionAnswersService
        .Create(item)
        .then(() => {
          notificationServiceBus.showSuccessMessage(
            "Successo!",
            "Adicionou a pergunta com sucesso!"
          );
          this.onResetAnswers();
          this.loadAnswers();
        })
        .catch((error) => {
          this.isLoading = false;
          notificationServiceBus.showError(error.response);
        });
    },
    async updateAnswer(item) {
      this.isLoading = true;
      courseContentQuestionAnswersService
        .Update(item)
        .then(() => {
          notificationServiceBus.showSuccessMessage(
            "Successo!",
            "Atualizou a pergunta com sucesso!"
          );
          this.loadAnswers();
          this.onResetAnswers();
        })
        .catch((error) => {
          this.isLoading = false;
          notificationServiceBus.showError(error.response);
        });
    },
    async deleteAnswer(item) {
      var id = item.IdCourseContentQuestionAnswer;
      kendo.confirm("Tem a certeza que quer apagar pergunta?").done(() => {
        this.isLoading = true;
        courseContentQuestionAnswersService
          .Delete(id)
          .then(() => {
            notificationServiceBus.showSuccessMessage(
              "Successo!",
              "Apagou a resposta com sucesso!"
            );
            this.onResetAnswers();
            this.Items = this.Items.filter(
              (s) =>
                s.IdCourseContentQuestionAnswer !==
                item.IdCourseContentQuestionAnswer
            );
          })
          .catch((error) => {
            this.isLoading = false;
            notificationServiceBus.showError(error.response);
          });
      });
    },
    async loadAnswers() {
      await courseContentQuestionAnswersService
        .Get({
          Filters: [
            {
              Field: "IdCourseContentQuestion",
              Operator: "eq",
              Value: this.question.IdCourseContentQuestion,
            },
          ],
          Order: [{ Field: "OrderId", Direction: "ASC" }],
        })
        .then((response) => {
          this.Items = response.data.Items.map((item) =>
            Object.assign(
              {
                inEdit: false,
              },
              item
            )
          );
        });
    },
  },
  async created() {
    await this.loadAnswers();
  },
};
</script>

<style scoped></style>
