import axios from "axios";
import { API_URL } from "@/settings";
import * as types from "@/store/types";

const instance = axios.create();

const baseUrl = API_URL + "api/portfolios";

const endPoints = {
  Get: baseUrl + "/fetch",
  Update: baseUrl + "/update",
  Create: baseUrl + "/create",
  Delete: baseUrl + "/delete",
  Statistics: baseUrl + "/statistics",
  AddCourse: baseUrl + "/courses",
  RelatedDate: baseUrl + "/related-data",
};

// eslint-disable-next-line prettier/prettier
instance.defaults.headers.common["Authorization"] = `Bearer ${window.localStorage.getItem(types.GLOBAL_USER_TOKEN)}`;
instance.defaults.headers.common["Content-Type"] = "application/json";

const actions = {
  Get(options) {
    return instance.post(endPoints.Get, {
      ...{
        Filters: [],
      },
      ...options,
    });
  },
  GetSingle(id, options) {
    return instance.get(endPoints.Get + "/" + id, {
      params: options,
    });
  },
  GetStatistics(id) {
    return instance.get(endPoints.Statistics + "/" + id);
  },
  GetRelatedData(id, options) {
    return instance.get(endPoints.RelatedDate + "/" + id, {
      params: options,
    });
  },
  Delete(id, options) {
    const data = {
      ...options,
    };
    return instance.delete(endPoints.Delete + "/" + id, {
      data,
    });
  },
  Update(item, options = {}) {
    const data = {
      Instance: item,
      ...options,
    };
    return instance.put(endPoints.Update, data);
  },
  Create(item, options = {}) {
    const data = {
      Instance: item,
      ...options,
    };
    return instance.post(endPoints.Create, data);
  },
  UpdateCourses(IdPortfolio, Courses, TypeId, RestrictionTypeId, options = {}) {
    const data = {
      IdPortfolio,
      Courses,
      TypeId,
      RestrictionTypeId,
      ...options,
    };
    return instance.put(endPoints.AddCourse, data);
  },
};

export default actions;
