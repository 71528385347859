<template>
  <v-container v-if="portfolio">
    <Grid
      :style="{ height: 'auto' }"
      :data-items="Items"
      :columns="Columns"
      :pageable="true"
      :skip="skip"
      :take="take"
      :total="grid.pagging.total"
      @pagechange="pageChange"
    >
      <template slot="linkCell" slot-scope="{ props }">
        <td :class="props.className">
          <router-link :to="`/users/${props.dataItem.IdUser}`">
            {{ props.dataItem[props.field] }}
          </router-link>
        </td>
      </template>
    </Grid>
  </v-container>
</template>
<script>
import { notificationServiceBus } from "@/services/bus/notificationServiceBus";
import GridHelper from "@/utilities/grid";
import usersService from "@/services/api/usersService";

export default {
  props: {
    portfolio: { type: Object },
  },
  data() {
    return {
      grid: {
        sort: [],
        pagging: {
          pageId: 1,
          pageSize: 10,
          total: 0,
        },
      },
      Items: [],
      Columns: [
        {
          field: "Code",
          title: "Código",
          cell: "linkCell",
          width: 150,
        },
        {
          field: "Name",
          title: "Nome",
          cell: "linkCell",
        },
      ],
    };
  },
  computed: {
    skip() {
      return (this.grid.pagging.pageId - 1) * this.grid.pagging.pageSize;
    },
    take() {
      return this.grid.pagging.pageSize;
    },
  },
  methods: {
    pageChange(ev) {
      this.grid.pagging.pageId = ev.page.skip / ev.page.take + 1;
      this.loadItems();
    },
    async loadItems() {
      let options = GridHelper.ConvertOptionsFromNativeKendoUIGrid(
        this.grid.pagging,
        null,
        this.grid.sort
      );
      if (this.portfolio.IdJobCostCenter) {
        options.Filters.push({
          Field: "IdJobCostCenter",
          Operation: "eq",
          Value: this.portfolio.IdJobCostCenter,
        });
      }
      if (this.portfolio.IdJobDepartment) {
        options.Filters.push({
          Field: "IdJobDepartment",
          Operation: "eq",
          Value: this.portfolio.IdJobDepartment,
        });
      }
      await usersService
        .Get(options)
        .then((res) => {
          this.Items = res.data.Items;
          this.grid.pagging.total = res.data.Total;
          this.resetInstance();
        })
        .catch((error) => {
          notificationServiceBus.showError(error.response);
        });
    },
  },
  async created() {
    await this.loadItems();
  },
};
</script>

<style scoped></style>
