<!-- eslint-disable -->
<template>
  <v-container grid-list v-if="course">
    <div class="k-grid k-grid-toolbar">
      <app-form-modal :title-dialog="`${isNew ? 'Adicionar' : 'Editar'} Ação de Formação`" :title-button="'Adicionar Ação de Formação'" :confirmAction="saveInstance" :cancelAction="clearData" :formValid="!$v.Instance.$error && Instance.Name.length > 2" ref="modal">
        <div class="custom-grid">
          <v-layout wrap row>
            <v-flex xs12>
              <v-text-field label="Nome da Ação de Formação" v-model="Instance.Name"></v-text-field>
            </v-flex>
            <v-flex xs12>
              <v-radio-group v-model="Instance.HasSessions" row label="Tem Sessões?">
                <v-radio label="Sim" :value="true"></v-radio>
                <v-radio label="Não" :value="false"></v-radio>
              </v-radio-group>
            </v-flex>
            <v-flex xs6>
              <app-datetime-picker v-model="Instance.DateStart" label="Data Início"></app-datetime-picker>
            </v-flex>

            <v-flex xs6 v-if="Instance.HasSessions">
              <app-datetime-picker v-model="Instance.DateEnd" label="Data Fim"></app-datetime-picker>
            </v-flex>
            <v-flex xs6 v-else>
              <v-text-field type="number" label="Duração em horas" v-model="Instance.Hours"></v-text-field>
            </v-flex>

            <v-flex xs12>
              <v-radio-group v-model="HasExternalLocation" row label="Localização Externa?">
                <v-radio label="Sim" :value="true"></v-radio>
                <v-radio label="Não" :value="false"></v-radio>
              </v-radio-group>
            </v-flex>
            <v-flex xs12 v-if="!HasExternalLocation">
              <v-select :items="locationsFiltered" label="Localização" item-text="Description" v-model="Instance.IdJobLocation" item-value="IdJobLocation" :clearable="true"></v-select>
            </v-flex>
            <v-flex xs12 v-else>
              <v-text-field required label="Localização ext/Link" v-model="Instance.ExternalLocation"></v-text-field>
            </v-flex>
            <v-flex xs12>
              <v-select label="Metodologia de ensino" :items="CourseArchitectureTypes" item-text="Name" item-value="TypeId" v-model="Instance.TypeId"></v-select>
            </v-flex>
            <!-- <v-flex xs12 v-if="Instance.TypeId === 5">
              <v-text-field
                label="Link da Formação"
                v-model="Instance.ExternalLink"
              ></v-text-field>
            </v-flex> -->
            <v-flex xs6>
              <v-text-field type="number" step="1" label="Limite de Inscrições" v-model="Instance.MaxInscriptions"></v-text-field>
            </v-flex>
            <v-flex xs6>
              <v-text-field label="Horas" type="number" step="1" v-model="hours" :disabled="!course.IsClassesDurationEditable"></v-text-field>
            </v-flex>
            <v-flex xs12>
              <v-select :items="Surveys" label="Questionário de Avaliação de Satisfação" item-text="Name" item-value="IdSurvey" v-model="this.Instance.IdSatisfactionSurvey" :clearable="true"></v-select>
            </v-flex>
            <v-flex xs12>
              <v-radio-group v-model="Instance.ExternalTrainer" row @click="Instance.Formers = []">
                <v-radio label="Formador Interno" :value="false"></v-radio>
                <v-radio label="Formador Externo" :value="true"></v-radio>
              </v-radio-group>
            </v-flex>
            <v-flex xs12 v-if="!Instance.ExternalTrainer">
              <v-select :items="Trainers" label="Formadores" item-text="Name" v-model="Instance.Formers" item-value="IdUser" :multiple="true"></v-select>
            </v-flex>
            <v-flex xs12 v-else>
              <v-select :items="this.course.Suppliers" label="Fornecedores" item-text="Name" v-model="Instance.Supplier" item-value="IdSupplier" :multiple="false" :clearable="true"></v-select>
            </v-flex>
            <v-flex xs12>
              <v-text-field type="number" label="Ponderação de avaliação de formador" v-model="Instance.Ponderation"></v-text-field>
            </v-flex>
          </v-layout>
        </div>
      </app-form-modal>
    </div>
    <Grid :course="course" :style="{ height: 'auto' }" :data-items="Classes" :columns="Columns" @edit="edit" @remove="remove" :detail="cellTemplate" @expandchange="expandChange" :expand-field="'expanded'" :filterable="true" :filter="grid.filter" @filterchange="filterChange">
      <GridNoRecords>Não existem Ações de Formação</GridNoRecords>
      <template slot="dateCell" slot-scope="{ props }">
        <td :class="props.className">
          {{ props.dataItem.DateStart + " » " + props.dataItem.DateEnd }}
        </td>
      </template>
      <template slot="LocationCell" slot-scope="{ props }">
        <td>
          {{
    props.dataItem.JobLocation === null
      ? props.dataItem.ExternalLocation
      : props.dataItem.JobLocation.Name
          }}
        </td>
      </template>
    </Grid>
  </v-container>
</template>

<script>
import Vue from "vue";
import * as kendo from "@progress/kendo-ui";
import FormModalComponent from "@/components/modals/form-modal";
import SubscriptionsGridComponent from "@/components/grid/subscriptions.grid";
import AlertsGridComponent from "@/components/grid/alerts.grid";
import SessionsGridComponent from "../shared/sessions.grid";
import { required, minLength } from "vuelidate/lib/validators";
import { notificationServiceBus } from "@/services/bus/notificationServiceBus";
import courseClassesService from "@/services/api/courseClassesService";
import jobLocationsService from "@/services/api/jobLocationsService";
import surveysService from "@/services/api/surveyService";
import usersService from "@/services/api/usersService";
import EditCommandCell from "@/components/grid/edit-command.js";
import DeleteCommandCell from "@/components/grid/delete-command.js";
import GridHelper from "@/utilities/grid";
import DateTimePickerComponent from "@/components/forms/date-time-picker";
import { CourseArchitectureTypes } from "@/models/course-architecture-types";
import Dates from "@/utilities/dates";
import StringFilterCell from "@/components/grid/string-filter.js";
import { VTabs, VTab, VTabItem, VTextarea, VBtn } from "vuetify/lib";

Vue.component("app-inscriptions-grid", SubscriptionsGridComponent);
Vue.component("app-alerts-grid", AlertsGridComponent);
Vue.component("app-sessions-grid", SessionsGridComponent);
const componentInstance = Vue.component("app-inscriptions-grid-component", {
  components: {
    VTabs,
    VTab,
    VTabItem,
    VTextarea,
    VBtn,
  },
  props: ["dataItem", "Instance"],
  data () {
    return {
      tab: "inscriptions",
    };
  },
  methods: {
    async saveSummary (instance) {
      await courseClassesService
        .Update(instance)
        .then((response) => {
          notificationServiceBus.showSuccessMessage(
            "Successo!",
            "Editou a Ação de Formação com sucesso!"
          );
          this.isLoading = false;
          const index = this.Classes.findIndex(
            (s) => s.IdCourseClass == this.Instance.IdCourseClass
          );
          if (index >= 0) {
            this.Classes[index] = { ...response.data.Instance };
          }
        })
        .catch((error) => {
          this.isLoading = false;
          notificationServiceBus.showError(error.response);
        });
    },
  },
  template: `<v-tabs v-model="tab" slider-color="primary">
              <v-tab :key="'inscriptions'">Inscrições</v-tab>
              <v-tab v-if="dataItem.HasSessions" :key="'sessions'">Sessões</v-tab>
              <v-tab :key="'summary'">Sumário</v-tab>
              <v-tab :key="'Alerts'">Alertas</v-tab>
              <v-tab-item :key="'inscriptions'"><app-inscriptions-grid :courseClass="dataItem"></app-inscriptions-grid></v-tab-item>
              <v-tab-item :key="'sessions'"><app-sessions-grid :courseClass="dataItem"></app-sessions-grid></v-tab-item>
              <v-tab-item :key="'summary'"><v-textarea v-model="dataItem.Description">
              </v-textarea>
              <v-btn
                color="accent"
                class="ml-auto"
                type="button"
                @click="saveSummary(dataItem)"
                >Guardar</v-btn
              >
              </v-tab-item>
              <v-tab-item :key="'Alerts'"><app-alerts-grid :courseClass="dataItem"></app-alerts-grid></v-tab-item>
              </v-tabs>`,
});

export default {
  components: {
    "app-datetime-picker": DateTimePickerComponent,
    "app-form-modal": FormModalComponent,
  },
  props: ["course"],
  data () {
    return {
      hours: 0,
      HasSections: false,
      HasExternalLocation: false,
      cellTemplate: componentInstance,
      Trainers: [],
      Locations: [],
      Instance: {},
      Classes: [],
      Surveys: [],
      grid: {
        sort: [],
        filter: { logic: "and", filters: [] },
      },
      Columns: [
        { field: "Name", title: "Nome da Ação", filterCell: StringFilterCell },
        // {
        //   field: "JobLocation.Name",
        //   title: "Local",
        //   filterable: false,
        //   width: 200,
        //   cell: "LocationCell"
        // },
        {
          field: "DateStart",
          title: "Data",
          cell: "dateCell",
          filterable: false,
        },
        {
          field: "Minutes",
          title: "Minutos Formação",
          width: 115,
          filterable: false,
        },
        {
          field: "MaxInscriptions",
          title: "Limite inscrições",
          width: 115,
          filterable: false,
        },
        {
          cell: EditCommandCell,
          filterable: false,
          sortable: false,
          width: 75,
          title: "",
        },
        {
          cell: DeleteCommandCell,
          filterable: false,
          sortable: false,
          width: 75,
          title: "",
        },
      ],
      CourseArchitectureTypes: CourseArchitectureTypes,
      isLoading: false,
    };
  },
  validations: {
    Instance: {
      Name: {
        required,
        minLength: minLength(3),
      },
    },
  },
  watch: {
    hours (val) {
      if (this.Instance) {
        this.Instance.Minutes = parseInt(val * 60);
      }
    },
    Instance () {
      // this.Instance.TypeId = this.course.ArchitectureTypeId;
      this.Instance.IdSatisfactionSurvey = this.course.IdSatisfactionSurvey;
      this.Instance.ExternalLocation === null ||
        this.Instance.ExternalLocation === ""
        ? (this.HasExternalLocation = false)
        : (this.HasExternalLocation = true);
    },
  },
  computed: {
    isNew () {
      return this.Instance != null && this.Instance.IdCourseClass == 0;
    },
    locationsFiltered () {
      return this.Locations.map((entry) => {
        const Description = `${entry.Code} » ${entry.Name}`;
        return Object.assign({}, entry, { Description });
      }).sort((a, b) => (a.Description > b.Description ? 1 : -1));
    },
  },
  methods: {
    resetInstance () {
      this.isLoading = false;
      this.HasExternalLocation = false;
      this.HasSections = false;
      this.hours = parseInt(this.course.MinutesFormation / 60);

      this.Instance = {
        ExternalTrainer: false,
        IdCourseClass: 0,
        IdSatisfactionSurvey: this.course.IdSatisfactionSurvey,
        IdCourse: this.course.IdCourse,
        IdJobLocation: null,
        Name: "",
        Formers: [],
        TypeId: 1,
        Minutes: this.course.MinutesFormation,
        Hours: 0,
        ExternalLocation: "",
        DateStart: Dates.PresentDateWithFormat(
          new Date(),
          "DD-MM-YYYY",
          "DD-MM-YYYY HH:mm:ss"
        ),
        DateEnd: Dates.PresentDateWithFormat(
          new Date(),
          "DD-MM-YYYY",
          "DD-MM-YYYY HH:mm:ss"
        ),
      };
    },
    filterChange (ev) {
      //this.grid.pagging.pageId = 1;
      this.grid.filter = ev.filter;
      this.loadClasses();
    },
    sortChange (ev) {
      this.grid.sort = ev.sort;
      //this.grid.pagging.pageId = 1;
      this.loadClasses();
    },
    clearData (callBack) {
      this.resetInstance();
      callBack(true);
    },
    async saveInstance (callBack) {
      if (this.isLoading) return;
      this.isLoading = true;
      if (!this.Instance.HasSessions) {
        this.Instance.DateEnd = Dates.IncrementDateWithFormat(
          this.Instance.DateStart,
          "DD-MM-YYYY HH:mm:ss",
          "DD-MM-YYYY HH:mm:ss",
          "hours",
          this.Instance.Hours
        );
      }
      if (this.HasExternalLocation) {
        this.Instance.IdJobLocation = null;
      } else {
        this.Instance.ExternalLocation = null;
      }
      if (this.isNew) {
        this.Instance.IdCourse = this.course.IdCourse;
        await courseClassesService
          .Create(this.Instance)
          .then((response) => {
            notificationServiceBus.showSuccessMessage(
              "Successo!",
              "Criou a Ação de Formação com sucesso!"
            );
            this.isLoading = false;
            this.Classes.push(response.data.Instance);
            this.resetInstance();
            callBack(true);
          })
          .catch((error) => {
            this.isLoading = false;
            callBack(false);
            notificationServiceBus.showError(error.response);
          });
      } else {
        await courseClassesService
          .Update(this.Instance)
          .then(() => {
            notificationServiceBus.showSuccessMessage(
              "Successo!",
              "Editou a Ação de Formação com sucesso!"
            );
            this.isLoading = false;
            callBack(true);
            this.resetInstance();
            this.loadClasses();
          })
          .catch((error) => {
            this.isLoading = false;
            callBack(false);
            notificationServiceBus.showError(error.response);
          });
      }
    },
    deleteInstance (item) {
      var id = item.IdCourseClass;
      kendo
        .confirm("Tem a certeza que quer apagar a Ação de Formação?")
        .done(() => {
          courseClassesService
            .Delete(id)
            .then(() => {
              notificationServiceBus.showSuccessMessage(
                "Successo!",
                "Apagou a Ação de Formação com sucesso!"
              );
              this.Classes = this.Classes.filter((s) => s.IdCourseClass !== id);
            })
            .catch((error) => {
              notificationServiceBus.showError(error.response);
            });
        });
    },
    edit (item) {
      this.Instance = { ...item.dataItem };
      this.hours = item.dataItem.Minutes / 60;
      this.$refs.modal.open();
    },
    remove (item) {
      this.deleteInstance(item.dataItem);
    },
    async loadClasses () {
      let data = GridHelper.ConvertOptionsFromNativeKendoUIGrid(
        {},
        this.grid.filter,
        this.grid.sort
      );
      data.Filters.push({
        Field: "IdCourse",
        Operation: "eq",
        Value: this.course.IdCourse,
      });
      await courseClassesService
        .Get(data)
        .then((res) => {
          this.Classes = res.data.Items;
          //this.grid.pagging.total = res.data.Total;
        })
        .catch((error) => {
          notificationServiceBus.showError(error.response);
        });
    },
    async loadTrainers () {
      await usersService
        .Get({ Filters: [{ Field: "IdUserRole", Operation: "eq", Value: 2 }] })
        .then((res) => {
          this.Trainers = res.data.Items;
        })
        .catch((error) => {
          notificationServiceBus.showError(error.response);
        });
    },
    async loadLocations () {
      await jobLocationsService
        .Get({
          Filters: [],
        })
        .then((res) => {
          this.Locations = res.data.Items;
        })
        .catch((error) => {
          notificationServiceBus.showError(error.response);
        });
    },
    async loadSurveys () {
      await surveysService
        .Get({
          Filters: [{ Field: "TypeId", Operation: "eq", Value: 1 }],
        })
        .then((res) => {
          this.Surveys = res.data.Items;
        })
        .catch((error) => {
          notificationServiceBus.showError(error.response);
        });
    },
    expandChange (ev) {
      Vue.set(ev.dataItem, ev.target.$props.expandField, ev.value);
    },
  },
  async created () {
    this.resetInstance();
    await this.loadClasses();
    await this.loadTrainers();
    await this.loadLocations();
    await this.loadSurveys();
  },
};
</script>

<style scoped></style>
