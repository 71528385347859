<template>
  <div>
    <v-container>
      <h1>Mensagens</h1>
    </v-container>
  </div>
</template>

<script>
export default {
  data() {
    return {};
  },
  created() {},
  methods: {},
};
</script>

<style scoped></style>
