import axios from "axios";
import { API_URL } from "@/settings";
import * as types from "@/store/types";

const instance = axios.create();

const baseUrl = API_URL + "api/usercoursesubscriptions/presences";

const endPoints = {
  Get: baseUrl + "/",
  Update: baseUrl + "/",
};

// eslint-disable-next-line prettier/prettier
instance.defaults.headers.common["Authorization"] = `Bearer ${window.localStorage.getItem(types.GLOBAL_USER_TOKEN)}`;
instance.defaults.headers.common["Content-Type"] = "application/json";

const actions = {
  Get(idCourseClass, idCourseSession) {
    return instance.get(endPoints.Get + `${idCourseClass}/${idCourseSession}`);
  },
  Update(idCourseSession, idSubscription, wasPresent) {
    return instance.put(
      endPoints.Get + `${idCourseSession}/${idSubscription}`,
      { WasPresent: wasPresent }
    );
  },
};

export default actions;
