import Vue from "vue";
import DatePickerComponent from "@/components/forms/date-picker";
import { VSelect } from "vuetify/lib";

export default Vue.component("date-filter-component", {
  components: {
    "app-date-picker": DatePickerComponent,
    "v-select": VSelect,
  },
  props: {
    field: String,
    filterType: String,
    value: [String, Number, Boolean, Date],
    operator: String,
  },
  data() {
    return {
      commitedValue: "",
      currentOperator: "eq",
      currentValue: "",
      operators: [
        { Title: "Igual", Value: "eq" },
        { Title: "Maior ou igual", Value: "gte" },
        { Title: "Maior", Value: "gt" },
        { Title: "Menor ou igual", Value: "lte" },
        { Title: "Menor", Value: "lt" },
      ],
    };
  },
  created() {
    if (this.operator) {
      this.currentOperator = this.operators.find(
        (s) => s.Value == this.operator
      );
    } else {
      this.currentOperator = this.operators[0].Value;
    }
    this.currentValue = this.value;
  },
  template: `<div class="grid-cell-filter">
                <v-select @change='changeOperator' class="grid-cell-filter__operator"
                :items="operators"
                :item-text="'Title'"
                :item-value="'Value'"
                label="Filtro"
                v-model="currentOperator"
                ></v-select>
                <app-date-picker ref='picker' label="Pesquisa" v-model='currentValue' @input='changeText'></app-date-picker>
                <button v-if="currentValue" @click="reset" class="k-button k-button-icontext grid-cell-filter__action"><span class="icon-close"></span></button>
            </div>`,
  methods: {
    changeOperator(ev) {
      if (this.commitedValue) {
        this.$emit("change", {
          operator: this.currentOperator,
          field: this.field,
          value: this.currentValue,
          syntheticEvent: ev,
        });
      }
    },
    changeText(ev) {
      if (this.commitedValue != this.currentValue) {
        this.commitedValue = this.currentValue;
        if (this.currentValue == null) {
          this.$emit("change", {
            operator: "",
            field: "",
            value: "",
            syntheticEvent: ev,
          });
        } else {
          this.$emit("change", {
            operator: this.currentOperator,
            field: this.field,
            value: this.currentValue,
            syntheticEvent: ev,
          });
        }
      }
    },
    reset() {
      this.$refs.picker.clear();
      this.currentValue = null;
    },
  },
});
