import Vue from "vue";

export default Vue.component("order-cell-component", {
  props: {
    field: String,
    dataItem: Object,
  },
  template: `<td><button class="k-button k-button-icontext k-action-move">
              <span class="icon-move"></span>
            </button></td>`,
});
