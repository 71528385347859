<template>
  <div>
    <Grid
      :style="{ height: 'auto' }"
      :data-items="Items"
      :columns="Columns"
      :pageable="true"
      :skip="skip"
      :take="take"
      :total="grid.pagging.total"
      @pagechange="pageChange"
    >
      <template slot="userCell" slot-scope="{ props }">
        <td :class="props.className" v-if="$store.state.User.IdUserRole > 3">
          <router-link :to="`/users/${props.dataItem.IdUser}`">
            {{ props.dataItem.User[props.field.split(".")[1]] }}
          </router-link>
        </td>
        <td :class="props.className" v-else>
          {{ props.dataItem.User[props.field.split(".")[1]] }}
        </td>
      </template>
      <GridNoRecords>Não existem conflitos</GridNoRecords>
    </Grid>
  </div>
</template>

<script>
import subscriptionsService from "@/services/api/userCourseSubscriptionsService";
import StringFilterCell from "@/components/grid/string-filter.js";
import GridHelper from "@/utilities/grid";

export default {
  components: {},
  props: ["courseClass"],
  data() {
    return {
      grid: {
        sort: [],
        filter: { logic: "and", filters: [] },
        pagging: {
          pageId: 1,
          pageSize: 10,
          total: 0,
        },
      },
      isFit: null,
      isLoading: false,
      Items: [],
    };
  },
  computed: {
    skip() {
      return (this.grid.pagging.pageId - 1) * this.grid.pagging.pageSize;
    },
    take() {
      return this.grid.pagging.pageSize;
    },
    Columns() {
      let cols = [
        {
          field: "User.Code",
          title: "Código",
          cell: "userCell",
          width: 125,
          sortable: false,
          filterCell: StringFilterCell,
        },
        {
          field: "User.Name",
          title: "Aluno",
          cell: "userCell",
          sortable: false,
          filterCell: StringFilterCell,
        },
        {
          field: "CourseClass.Name",
          title: "Turma",
        },
        {
          field: "Course.Name",
          title: "Curso",
        },
      ];

      return cols;
    },
  },
  async created() {
    await this.loadAlerts();
  },
  watch: {
    // whenever question changes, this function will run
    isFit: function (val) {
      if (this.subscription) {
        if (val === true) {
          this.subscription.Classification = 100;
        } else {
          this.subscription.Classification = 10;
        }
      }
    },
  },
  methods: {
    pageChange(ev) {
      this.grid.pagging.pageId = ev.page.skip / ev.page.take + 1;
      this.loadItems();
    },
    async loadAlerts() {
      const options = GridHelper.ConvertOptionsFromNativeKendoUIGrid(
        this.grid.pagging,
        this.grid.filter,
        this.grid.sort
      );
      await subscriptionsService
        .Alerts(this.courseClass.IdCourseClass, options)
        .then((response) => {
          this.Items = response.data.Items;
          this.grid.pagging.total = response.data.Total;
        });
    },
  },
};
</script>

<style scoped>
.v-tabs__item {
  display: flex !important;
}
</style>
