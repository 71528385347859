<template>
  <span>
    {{ valueFormatted }}
  </span>
</template>
<script>
import Dates from "@/utilities/dates";
export default {
  props: ["value"],
  computed: {
    valueFormatted() {
      return Dates.PresentToDate(this.value);
    },
  },
};
</script>
