export const Satisfaction = {
  TypeId: 1,
  Name: "Satisfação",
};

export const Avaliation = {
  TypeId: 2,
  Name: "Avaliação",
};

export const Formation = {
  TypeId: 3,
  Name: "Avaliação de Formação",
};

export const SurveyTypes = [Satisfaction, Avaliation, Formation];

export function GetTypeNameById(id) {
  for (let index = 0; index < SurveyTypes.length; index++) {
    if (SurveyTypes[index].TypeId === id) {
      return SurveyTypes[index].Name;
    }
  }
  return "";
}
