export const Table_32 = [
  {
    Code: "01",
    Text: "Da responsabilidade do empregador ",
    Hint: "",
  },
  {
    Code: "02",
    Text: "Da iniciativa do trabalhador (ao abrigo do crédito de horas para formação contínua)",
    Hint: "Artº 132 da Lei nº7/2009, de 12 de fevereiro",
  },
  {
    Code: "03",
    Text: "Da iniciativa da empresa utilizadora de mão-de-obra",
    Hint: `A preencher apenas por empresas de trabalho temporário. Corresponde a formação da iniciativa da empresa utilizadora de mão-de-obra, ao abrigo do previsto no nº 8 do Artº 131 da Lei nº 7/2009, de 12 de Fevereiro.
    Se a formação profissional foi proporcionada pelo empregador (empresa de trabalho temporário) ao abrigo do previsto no nº 1 do Artº 187 da Lei 7/2009, de 12 de Fevereiro, deverá ser assinalado o código (1).`,
  },
];

export function GetTypeTextById(id) {
  for (let index = 0; index < Table_32.length; index++) {
    if (Table_32[index].Code === id) {
      return Table_32[index].Text;
    }
  }
  return "";
}
