export const SumOfTests = {
  TypeId: 1,
  Name: "Média de todos os testes",
};
export const AverageOfModules = {
  TypeId: 2,
  Name: "Média de todos os módulos",
};
export const SingleTest = {
  TypeId: 3,
  Name: "Baseado num único teste",
};
export const CourseAvaliationTypes = [AverageOfModules, SumOfTests, SingleTest];

export function GetTypeNameById(id) {
  for (let index = 0; index < CourseAvaliationTypes.length; index++) {
    if (CourseAvaliationTypes[index].TypeId === id) {
      return CourseAvaliationTypes[index].Name;
    }
  }
  return "";
}
