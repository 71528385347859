<template>
  <v-container v-if="portfolio">
    <courses-grid-component
      :courses="courses"
      :portfolio="portfolio"
      :typeId="currentType"
      :field="currentField"
    ></courses-grid-component>
  </v-container>
</template>
<script>
import CoursesGridComponent from "./grid";
import { PorfolioDurationTypes } from "@/models/portfolio-durations";

export default {
  props: {
    courses: { type: Array },
    portfolio: { type: Object },
  },
  components: {
    "courses-grid-component": CoursesGridComponent,
  },
  data() {
    return {
      currentType: PorfolioDurationTypes[0].TypeId,
      currentField: "BasicCourses",
    };
  },
};
</script>

<style scoped></style>
