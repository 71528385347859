export const HalfYear = {
  TypeId: 1,
  Name: "6 Meses",
};
export const OneYear = {
  TypeId: 2,
  Name: "1 Ano",
};
export const TwoYears = {
  TypeId: 3,
  Name: "2 Anos",
};
export const ThreeYears = {
  TypeId: 4,
  Name: "3 Anos",
};
export const FiveYears = {
  TypeId: 5,
  Name: "5 Anos",
};
export const CourseRepeatableTypes = [
  HalfYear,
  OneYear,
  TwoYears,
  ThreeYears,
  FiveYears,
];

export function GetRepeatableTypesTypeNameById(id) {
  for (let index = 0; index < CourseRepeatableTypes.length; index++) {
    if (CourseRepeatableTypes[index].TypeId === id) {
      return CourseRepeatableTypes[index].Name;
    }
  }
  return "";
}
