export const Table_29 = [
  {
    Code: "01",
    Text: "Frequentou formação profissional correspondente a direito adquirido no ano de referência",
  },
  {
    Code: "02",
    Text: "Frequentou formação profissional por direito adquirido nos dois anos anteriores ao ano de referência",
  },
  {
    Code: "03",
    Text: "Frequentou formação profissional por antecipação da aquisição do direito (nº 6 e 7, artº 131, Lei 7/2009 de 12 de fevereiro)",
  },
  {
    Code: "08",
    Text: "Outra situação",
  },
];

export function GetTypeTextById(id) {
  for (let index = 0; index < Table_29.length; index++) {
    if (Table_29[index].Code === id) {
      return Table_29[index].Text;
    }
  }
  return "";
}
